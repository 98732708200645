@import "https://static.blue.cl/fonts/fonts.css";
:root {
  --bx-color-blue: #3363ff;
  --bx-color-blue-space: #0a1433;
  --bx-color-blue-tea: #0f1e4c;
  --bx-color-blue-clip: #142866;
  --bx-color-blue-point: #193180;
  --bx-color-blue-cross: #1f3b99;
  --bx-color-blue-moon: #294fcc;
  --bx-color-blue-place: #294fcc;
  --bx-color-blue-flip: #2e59e5;
  --bx-color-blue-glam: #4773ff;
  --bx-color-blue-rock: #5c82ff;
  --bx-color-blue-fun: #7092ff;
  --bx-color-blue-air: #85a1ff;
  --bx-color-blue-soft: #99b1ff;
  --bx-color-blue-new: #adc1ff;
  --bx-color-blue-shine: #c2d0ff;
  --bx-color-blue-clear: #d6e0ff;
  --bx-color-blue-cloud: #ebefff;
  --bx-color-orange: #ff7e44;
  --bx-color-orange-blod: #190d07;
  --bx-color-orange-wine: #4c2614;
  --bx-color-orange-novel: #66321b;
  --bx-color-orange-space: #994c29;
  --bx-color-orange-go: #8f4726;
  --bx-color-orange-get: #cc6536;
  --bx-color-orange-full: #e5713d;
  --bx-color-orange-miles: #ff8b57;
  --bx-color-orange-gum: #ff9869;
  --bx-color-orange-point: #ffa57c;
  --bx-color-orange-fly: #ffb28f;
  --bx-color-orange-rubber: #ffbea1;
  --bx-color-orange-elastic: #ffca99;
  --bx-color-orange-candy: #ffd8c7;
  --bx-color-orange-rose: #ffe5da;
  --bx-color-orange-blank: #fff2ec;
  --bx-color-lblue: #2bb9ff;
  --bx-color-lblue-only: #041219;
  --bx-color-lblue-night: #092533;
  --bx-color-lblue-sea: #114a66;
  --bx-color-lblue-our: #155c80;
  --bx-color-lblue-well: #1a6f99;
  --bx-color-lblue-give: #1e81b2;
  --bx-color-lblue-bx: #2294cc;
  --bx-color-lblue-way: #27a6e5;
  --bx-color-lblue-sky: #40c0ff;
  --bx-color-lblue-ice: #55c7ff;
  --bx-color-lblue-drop: #6bceff;
  --bx-color-lblue-dream: #80d5ff;
  --bx-color-lblue-snow: #95dcff;
  --bx-color-lblue-winter: #aae3ff;
  --bx-color-lblue-easy: #bfeaff;
  --bx-color-lblue-thin: #d5f1ff;
  --bx-color-lblue-day: #eaf8ff;
  --bx-color-lorange: #fda460;
  --bx-color-lorange-state: #322615;
  --bx-color-lorange-grow: #654c2a;
  --bx-color-lorange-truck: #97723f;
  --bx-color-lorange-box: #b08549;
  --bx-color-lorange-pyme: #ca9854;
  --bx-color-lorange-try: #e3ab5e;
  --bx-color-lorange-summer: #fcbe69;
  --bx-color-lorange-fruit: #fcc478;
  --bx-color-lorange-row: #fdcb87;
  --bx-color-lorange-make: #fdd296;
  --bx-color-lorange-travel: #fdd8a5;
  --bx-color-lorange-send: #feecd2;
  --bx-color-lorange-skin: #fef2e1;
  --bx-color-black: #212121;
  --bx-color-grey-one: #333;
  --bx-color-grey-press: #4d4d4d;
  --bx-color-grey-mistery: #666;
  --bx-color-grey-play: gray;
  --bx-color-grey-time: #b3b3b3;
  --bx-color-grey-me: #ccc;
  --bx-color-grey-you: #e5e5e5;
  --bx-color-grey-up: #f6f6f6;
  --bx-color-grey-on: #fbfbfb;
  --bx-color-white: #fff;
  --bx-color-green-future: #408d5c;
  --bx-color-green-game: #effaf3;
  --bx-color-light-express: #00b4db;
  --bx-color-light-dream: #e5f7fb;
  --bx-color-red-alert: #fd2626;
  --bx-color-red-black: #4c0b0b;
  --bx-color-red-up: #7e1313;
  --bx-color-red-blood: #b11b1b;
  --bx-color-red-medium: #fe6767;
  --bx-color-red-nice: #fe9292;
  --bx-color-red-land: #febebe;
  --bx-color-red-clean: #ffe9e9;
  --bx--color-yellow-alert: #ffe27c;
  --bx--color-yellow-go: #665a32;
  --bx--color-yellow-gold: #99884b;
  --bx--color-yellow-brown: #e0c35c;
  --bx--color-yellow-air: #ffe896;
  --bx--color-yellow-nice: #ffeeb1;
  --bx--color-yellow-cloud: #fff6d8;
  --bx--color-yellow-blank: #fffcf2;
  --bx-bg: #fff;
  --bx-bg-secondary: #eaeaeb;
  --bx-fg: #18191a;
  --bx-grey: #eaeaea;
  --bx-selection: #000000fc;
  --bx-skeleton-bg: #0000001c;
  --bx-modal-border: #0003;
  --bx-bg-gradient: linear-gradient(85.92deg, var(--bx-color-blue) 14.32%, var(--bx-color-lblue) 99.53%);
  --bx-bg-login: var(--bx-bg-gradient);
  --bx-color-light: #f8f9fa;
  --bx-color-dark: #212529;
  --bx-color-secondary: #6c757d;
  --bx-logo-principal: #0033a1;
  --bx-logo-principal-inverted: #fff;
  --bx-logo-dot: #4ac1e0;
  --bx-button-fg: #18191a;
  --bx-button-hover: #000;
  --bx-button-secondary-fg: #f0f0f0;
  --bx-button-secondary-hover: #ced4da;
  --bx-button-bg-primary: var(--bx-color-orange);
  --bx-button-fg-primary: #fff;
  --bx-button-bg-primary-hover: var(--bx-color-orange-full);
  --bx-button-fg-primary-hover: #fff;
  --bx-login-button-bg: #fff;
  --bx-login-button-shadow: 0px 2px 4px -2px #18274b1f, 0px 4px 4px -2px #18274b14;
  --bx-alert-dark: #d3d3d4;
  --bx-alert-border-dark: #bcbebf;
  --bx-alert-danger: #dc3545;
  --bx-alert-success-border: var(--bx-color-green-future);
  --bx-alert-success-fg: var(--bx-color-green-future);
  --bx-alert-success-bg: var(--bx-color-green-game);
  --bx-alert-danger-border: var(--bx-color-red-alert);
  --bx-alert-danger-fg: var(--bx-color-red-alert);
  --bx-alert-danger-bg: var(--bx-color-red-clean);
  --bx-alert-info-border: var(--bx-color-light-express);
  --bx-alert-info-fg: var(--bx-color-light-express);
  --bx-alert-info-bg: var(--bx-color-light-dream);
  --bx-alert-warning-border: var(--bx--color-yellow-brown);
  --bx-alert-warning-fg: var(--bx--color-yellow-brown);
  --bx-alert-warning-bg: var(--bx--color-yellow-blank);
  --bx-form-input-border: #ced4da;
  --bx-form-text: #6c757d;
  --bx-form-disabled: #e9ecef;
  --bx-fv-valid: #28a745;
  --bx-fv-invalid: #dc3545;
  --bx-fv-invalid-rgba: #dc354540;
  --bx-progress-bg: #e9ecef;
  --bx-progress-success: #198754;
  --bx-progress-info: #0dcaf0;
  --bx-progress-warning: #ffc107;
  --bx-progress-danger: #dc3545;
  --bx-bg-register: var(--bx-color-lblue-day);
  --bx-register-header-bg: var(--bx-color-blue);
  --bx-register-avatar-edit-bg: var(--bx-color-blue);
  --bx-register-header-height: 64px;
  --bx-register-header-shadow: 0px 4px 6px -4px #18274b1f, 0px 8px 8px -4px #18274b14;
  --bx-register-footer-height: 56px;
  --bx-register-footer-fg: var(--bx-color-lblue-give);
}

body, html {
  height: 100%;
  width: 100%;
  font-family: Lato;
}

@media screen and (width >= 768px) {
  body, html {
    overflow: hidden;
  }
}

@media screen and (width <= 767px) {
  body, html {
    overflow: auto;
  }
}

#root {
  height: 100%;
}

div#content {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Regular.ad872c70.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.searchbar-container {
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px #0003;
}

@media screen and (width <= 767px) {
  .searchbar-container {
    position: relative;
    top: 0;
    transform: translate(0);
  }

  .hide-on-cellphone {
    display: none;
  }
}

@media (width >= 768px) {
  .hide-on-desktop-and-tablet {
    display: none;
  }
}

@media (width >= 992px) {
  .hide-on-desktop-and-tablet {
    display: none;
  }
}

.search-bar {
  max-width: 900px;
}

.autocomplete-container {
  max-width: 900px;
  z-index: 999;
  background-color: #fff;
  position: absolute;
  top: 70px;
}

@media (width <= 767px) {
  .autocomplete-container {
    top: 73px;
  }
}

.search-bar-container {
  z-index: 9999;
}

.search-icon {
  color: #2bb9ff;
  position: absolute;
  top: 10px;
  left: 16px;
}

.list-box-container {
  z-index: 100;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  background: #fff;
  padding: 0;
  position: absolute;
  overflow-y: auto;
  box-shadow: 0 0 10px #0003;
}

@media (width >= 768px) {
  .list-box-container {
    max-width: 310px;
    top: 0;
  }
}

@media (width >= 992px) {
  .list-box-container {
    max-width: 350px;
    top: 0;
  }
}

@media (width <= 767px) {
  .list-box-container {
    max-width: 100%;
    height: auto;
    max-height: initial;
    bottom: 0;
    top: initial;
    border-radius: 0;
    flex: 1;
    margin: 0;
    position: relative;
    overflow-y: scroll;
  }
}

.list-box-container .list-box {
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
  padding: 12px;
}

.icon-container {
  width: 24px;
}

.text-km {
  white-space: nowrap;
}

.ps-3-5 {
  padding-left: 3rem;
}

.ps-2 {
  padding-left: 2rem;
}

.p-0-3rem {
  padding: .3rem !important;
}

.fs-sm {
  font-size: .875rem;
}

.fs-xs {
  font-size: .75rem;
}

.fs-13px {
  font-size: 13px !important;
}

.map-and-listbox-container-w-nobanner {
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
}

@media (width <= 767px) {
  .map-and-listbox-container-w-nobanner {
    height: 100vh;
    flex-direction: column;
    display: flex;
  }
}

.map-and-listbox-container-w-banner {
  height: 100%;
  width: 100%;
}

@media (width <= 767px) {
  .map-and-listbox-container-w-banner {
    height: calc(100vh - 80px);
    flex-direction: column;
    display: flex;
  }
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.btn-options {
  color: #fff;
  background-color: #ff7e44;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: .75rem .5rem;
  font-size: 1rem;
  display: flex;
}

.btn-options:hover {
  color: #fff;
  background-color: #e66a2e;
}

.btn-options:active {
  color: #fff !important;
  background-color: #d95b2a !important;
}

.text-hl {
  color: #ff7e44;
}

.color-text-blue {
  color: #0032a0;
}

.color-text-skyblue {
  color: #2bb9ff;
}

.color-text-orange {
  color: #ff7e44;
}

.cursor-pointer {
  cursor: pointer;
}

.selected-list-box {
  border-radius: 4px;
  animation: 1s ease-in-out forwards highlight;
}

@keyframes highlight {
  0% {
    background-color: #e5f7ff;
  }

  100% {
    background-color: #f0f0f0;
  }
}

.text-main {
  font-size: .9rem;
  font-weight: 700;
}

.map-container {
  width: auto !important;
}

@media (width <= 767px) {
  .map-container {
    margin-left: 0;
    height: 60% !important;
  }
}

@media (width >= 768px) {
  .map-container {
    margin-left: 310px;
  }
}

@media (width >= 992px) {
  .map-container {
    margin-left: 350px;
  }
}

.scroll-down-bar {
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 0;
  display: flex;
}

@media (width >= 768px) {
  .scroll-down-bar {
    display: none;
  }
}

.scroll-down-bar .bar {
  width: 40%;
  max-width: 120px;
  height: 8px;
  background-color: #e5e5e5;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.location-search-input {
  border-radius: 12px !important;
  font-size: 14px !important;
}

@media screen and (width <= 767px) {
  .location-search-input {
    padding-right: 3rem;
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.font-lato {
  font-family: Lato;
}

.bg-color-24-7 {
  background-color: #7dd59d;
}

.border-radius-8px {
  border-radius: 8px;
}

/*# sourceMappingURL=index.056e67b1.css.map */
