@import url("colors.css");
@import url("https://static.blue.cl/fonts/fonts.css");

body,
html {
  height: 100%;
  width: 100%;
  font-family: "Lato";
  //media querie tablet & desktop
  @media screen and (min-width: 768px) {
    overflow: hidden;
  }
  @media screen and (max-width: 767px) {
    overflow: auto;
  }
}
#root {
  height: 100%;
}
div#content {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Lato";
  src: url("../../public/assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.searchbar-container {
  position: sticky;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 767px) {
    position: relative;
    top: 0;
    transform: translate(0);
  }
}
.hide-on-cellphone {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.hide-on-desktop-and-tablet {
  //media screen tablet
  @media (min-width: 768px) {
    display: none;
  }
  //media screen desktop
  @media (min-width: 992px) {
    display: none;
  }
}
.search-bar {
  max-width: 900px;
}
.autocomplete-container {
  background-color: #ffffff;
  max-width: 900px;
  top: 70px;
  position: absolute;
  z-index: 999;

  // media querie mobile
  @media (max-width: 767px) {
    top: 140px;
    top: 73px;
  }
}

.search-bar-container {
  z-index: 9999;
}
.search-icon {
  position: absolute;
  top: 10px;
  left: 16px;
  color: #2bb9ff;
}

.list-box-container {
  position: absolute;
  z-index: 100;
  background: rgb(255, 255, 255);
  width: 100%;
  max-width: 500px;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  max-height: 100%;
  overflow-y: auto;

  @media (min-width: 768px) {
    max-width: 310px;
    top: 0;
  }
  //media screen desktop
  @media (min-width: 992px) {
    max-width: 350px;
    top: 0;
  }
  //media screen mobile
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0;
    border-radius: 0;
    bottom: 0;
    position: relative;
    // transform: translateY(-40vh);
    height: auto;
    max-height: initial;
    top: initial;

    overflow-y: scroll;
    flex: 1;
  }
  .list-box {
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5;
    padding: 12px;
  }
}

.icon-container {
  width: 24px;
}

.text-km {
  //color: #ff7e44;
  white-space: nowrap;
}
.ps-3-5 {
  padding-left: 3rem;
}

.ps-2 {
  padding-left: 2rem;
}

.p-0-3rem {
  padding: 0.3rem 0.3rem !important;
}

.fs-sm {
  font-size: 0.875rem;
}
.fs-xs {
  font-size: 0.75rem;
}

.fs-13px {
  font-size: 13px !important;
}

.map-and-listbox-container-w-nobanner {
  //height: calc(100vh - 118px);
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;

  @media (max-width: 767px) {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
.map-and-listbox-container-w-banner {
  height: 100%;
  width: 100%;

  @media (max-width: 767px) {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
  }
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.btn-options {
  background-color: #ff7e44;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  border-radius: 12px;
  &:hover {
    background-color: #e66a2e;
    color: white;
  }
  &:active {
    background-color: #d95b2a !important;
    color: white !important;
  }
}

.text-hl {
  color: #ff7e44;
}

.color-text-blue {
  color: #0032a0;
}

.color-text-skyblue {
  color: rgba(43, 185, 255, 1);
}
.color-text-orange {
  color: #ff7e44;
}
.cursor-pointer {
  cursor: pointer;
}

.selected-list-box {
  border-radius: 4px;
  animation: highlight 1s ease-in-out;
  animation-fill-mode: forwards;
  @keyframes highlight {
    0% {
      background-color: #e5f7ff;
    }

    100% {
      background-color: #f0f0f0;
    }
  }
}

.text-main {
  font-size: 0.9rem;
  font-weight: 700;
  // color: #0032a0;
}

.map-container {
  //height: 100% !important;
  width: auto !important;
  //mobile meque querie
  @media (max-width: 767px) {
    //position: absolute !important;
    margin-left: 0px;
    height: 60% !important;
  }

  //media screen tablet
  @media (min-width: 768px) {
    margin-left: 310px;
    //width: 100%;
  }
  //media screen desktop
  @media (min-width: 992px) {
    margin-left: 350px;
    //width: 100%;
  }
}

.scroll-down-bar {
  @media (min-width: 768px) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 0 1rem;

  .bar {
    width: 40%;
    max-width: 120px;
    background-color: #e5e5e5;
    height: 8px;
    border-radius: 6px;
    margin-bottom: 1rem;
  }
}

.location-search-input {
  border-radius: 12px !important;
  font-size: 14px !important;
  @media screen and (max-width: 767px) {
    padding-right: 3rem;
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.font-lato {
  font-family: "Lato";
}

.bg-color-24-7 {
  // background: rgba(254, 236, 210, 1) !important;
  background-color: rgba(125, 213, 157, 1);
}
.border-radius-8px {
  border-radius: 8px;
}